import { createStore } from "vuex";
import { registerWidgets as registerQuoteWidgets } from "@encapto/alchemy-quote-widgets";
import { registerWidgets as registerInventoryWidgets } from "@encapto/alchemy-inventory-widgets";
import { registerWidgets as registerClusterWidgets } from "@encapto/alchemy-cluster-widgets";
import { registerLoginWidgets } from "@encapto/alchemy-login-widget";
import { registerWidgets as registerSecurityPageUmbrellaWidgets } from "@encapto/cisco-umbrella-widgets";
import {
  registerDashboardPageSecurityWidgets,
  registerDashboardPageNetworkStatus as registerDashboardPageMerakiNetworkStatusWidgets,
  registerWifiPageWidgets as registerWifiPageMerakiWidgets,
  registerSDWANPageWidgets,
  registerFirewallPageWidgets,
  registerNetworkingPageWidgets,
  registerCameraPageWidgets
} from "@encapto/meraki-widgets";
import {
  registerManageWiFiPageWidgets,
  registerDashboardPageWidgets as registerDashboardPageWifiWidgets,
  registerWifiPageWidgets
} from "@encapto/alchemy-wifi-widgets";

import { registerAlchemyBrandingWidgets } from "@encapto/alchemy-branding-widgets";
import { registerAlchemyUserWidgets } from "@encapto/alchemy-user-widgets";
import { registerOrderListWidgets } from "@encapto/alchemy-order-widgets";
import { registerAlchemyUsageWidgets } from "@encapto/alchemy-usage-widgets";
import { registerWidgets as registerAccountsWidgets } from "@encapto/alchemy-accounts-widgets";
import { registerWidgets as registerOnboardingWidgets } from "@encapto/alchemy-onboarding-widgets";
import { registerWidgets as registerElasticsearchWidgets } from "@encapto/alchemy-elasticsearch-widgets";

import { storeUtils } from "@encapto/alchemy-gui";
import { actions } from "./store/actions";
import { getters, LAST_24_HOURS } from "./store/getters";
import { mutations } from "./store/mutations";

import { namespaces } from "./lib-api/view-utils";

export default createStore({
  modules: {
    ...registerLoginWidgets("alchemyLogin"),
    ...registerAlchemyUserWidgets(),
    ...registerOrderListWidgets(),
    ...registerDashboardPageSecurityWidgets({
      fullParentPath: [namespaces.dashboardMerakiSecurityNamespace],
      fnPrefix: "merchant-dashboard-meraki-security-"
    }),
    ...registerDashboardPageMerakiNetworkStatusWidgets({
      fullParentPath: ["@encapto/meraki/dashboard-network-status"],
      fnPrefix: "merchant-dashboard-network-status-"
    }),
    ...registerWifiPageMerakiWidgets({
      fullParentPath: [namespaces.wifiMerakiNamespace],
      fnPrefix: "merchant-wifi-"
    }),
    ...registerSDWANPageWidgets({
      fullParentPath: [namespaces.sdWanMerakiNamespace],
      fnPrefix: "merchant-sdwan-"
    }),
    ...registerFirewallPageWidgets({ fullParentPath: ["@encapto/meraki/firewall"] }),
    ...registerNetworkingPageWidgets({
      fullParentPath: [namespaces.networkingMerakiNamespace],
      fnPrefix: "merchant-networking-"
    }),
    ...registerCameraPageWidgets({
      fullParentPath: [namespaces.cameraMerakiNamespace],
      fnPrefix: "merchant-camera-"
    }),
    ...registerManageWiFiPageWidgets({
      fullParentPath: [namespaces.manageWifiNamespace],
      fnPrefix: "sp-manage-"
    }),
    ...registerDashboardPageWifiWidgets({
      fullParentPath: [namespaces.dashboardWifiNamespace],
      fnPrefix: "merchant-dashboard-"
    }),
    ...registerWifiPageWidgets({
      fullParentPath: [namespaces.wifiNamespace],
      fnPrefix: "merchant-wifi-"
    }),
    ...registerAlchemyBrandingWidgets(),
    ...registerAlchemyUsageWidgets(),
    ...registerAccountsWidgets({ fnPrefix: "sp-customers-" }),
    ...registerOnboardingWidgets(),
    ...registerElasticsearchWidgets(),
    ...registerQuoteWidgets(),
    ...registerClusterWidgets(),
    ...registerSecurityPageUmbrellaWidgets({
      fullParentPath: [namespaces.securityUmbrellaNamespace],
      fnPrefix: "merchant-security-"
    }),
    ...registerInventoryWidgets({
      fullParentPath: [namespaces.serviceProviderInventoryWidgetsNamespace],
      prefix: "sp"
    }),
    ...registerInventoryWidgets({
      fullParentPath: [namespaces.clusterAdminInventoryWidgetsNamespace],
      prefix: "admin"
    }),
    ...registerInventoryWidgets({
      fullParentPath: [namespaces.distributorInventoryWidgetsNamespace],
      prefix: "distributor"
    })
  },
  state: {
    info: {
      applicationName: "Alchemy SMB",
      version: ""
    },
    session: {},
    redirect: {},
    redirecting: false,
    busy: {},
    userErrors: [],
    brandingEntity: "",
    branding: {},
    openIdProviders: [],
    errors: [],
    currentError: {},
    availableRoutes: [],
    activeRoute: "",
    isSso: false,
    owningEntity: null,
    selectedRootEntity: null,
    routeRootReference: "",
    availableEntityTypes: [],
    availableOwnerTypes: [],
    routerQuery: {},
    dashboard: { period: LAST_24_HOURS, customDates: {} },
    supportLinks: {},
    loadingPageVisible: true,
    clusterFeatures: {},
    announcements: []
  },
  mutations: { ...storeUtils.mutations, ...mutations },
  actions,
  getters: { ...storeUtils.getters, ...getters }
});
