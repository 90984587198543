<template>
  <Transition>
    <div
      data-name="announcement"
      class="card d-flex flex-row align-items-center py-2"
      v-if="announcement"
    >
      <div class="px-3">
        <alchemy-orb dataName="announcement-orb" color="gray-blue" colorVariant="light" :size="2.5">
          <alchemy-icon dataName="announcement-icon" :icon="icon" size="1x" class="text-blue-400" />
        </alchemy-orb>
      </div>
      <div>
        <div data-name="title">
          <strong>{{ title }}</strong>
        </div>
        <div>
          <span ref="msgBox" data-name="message" v-html="message"></span>
        </div>
      </div>
      <div class="ml-auto mb-auto pr-1">
        <alchemy-button dataName="hide-button" variant="link" @click="onHideAnnouncement"
          >Close</alchemy-button
        >
      </div>
    </div>
  </Transition>
</template>

<script>
import _ from "lodash";
// eslint-disable-next-line import/no-extraneous-dependencies
import DOMPurify from "dompurify";
import { mapGetters } from "vuex";
import { faTruckFast, faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { iconApi } from "@encapto/alchemy-gui";
import Vue from "vue";
import { TRY_GET_ANNOUNCEMENTS, TRY_HIDE_ANNOUNCEMENT } from "../store/actions";
import { ANNOUNCEMENTS } from "../store/getters";

iconApi.library.add(faTruckFast, faBullhorn);

export default {
  name: "announcement",
  mounted() {
    this.$store.dispatch(TRY_GET_ANNOUNCEMENTS).then(() => {
      this.$nextTick(() => {
        this.updateInternalLinks();
      });
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.routeName = route.name;
        this.routeQuery = route.query;
        this.$nextTick(() => {
          this.updateInternalLinks();
        });
      }
    }
  },
  data() {
    return {
      icons: {
        "moved-feature": "truck-fast",
        "new-feature": "bullhorn"
      },
      routeName: "",
      routeQuery: {}
    };
  },
  computed: {
    ...mapGetters({
      announcements: ANNOUNCEMENTS
    }),
    locationIds() {
      const route = this.routeName;
      const { tab } = this.routeQuery;

      const locationIds = [route];
      if (tab) {
        locationIds.push(`${route}#${tab}`);
      }
      return locationIds;
    },
    announcement() {
      return this.announcements.find(
        a => _.intersection(_.get(a, "configuration.locationIds", []), this.locationIds).length > 0
      );
    },
    title() {
      return _.get(this.announcement, "configuration.title", "");
    },
    message() {
      return DOMPurify.sanitize(_.get(this.announcement, "configuration.message"), {
        ADD_TAGS: ["router-link"],
        ADD_ATTR: ["tab"]
      });
    },
    icon() {
      return _.get(this.icons, _.get(this.announcement, "configuration.type", "new-feature"));
    }
  },
  methods: {
    onHideAnnouncement() {
      this.$store.dispatch(TRY_HIDE_ANNOUNCEMENT, this.announcement);
    },
    updateInternalLinks() {
      if (!this.$refs.msgBox) return;
      const internalLinks = this.$refs.msgBox.getElementsByTagName("router-link");
      Array.from(internalLinks).forEach(anchor => {
        const routeName = anchor.getAttribute("name");
        if (!routeName) return; // probably a normal external link.
        const tab = anchor.getAttribute("tab");

        // Building the router-link
        const propsData = { to: { name: routeName, query: { tab } } };
        const RouterLink = Vue.component("RouterLink");
        const routerLink = new RouterLink({ propsData, parent: this });
        routerLink.$mount(anchor); // Replaces original anchor element with new routerLink.
        routerLink.$el.innerHTML = anchor.innerHTML;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-leave-to {
  opacity: 0;
}
</style>
