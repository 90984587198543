import { createRouter, createWebHistory } from "vue-router";
import _ from "lodash";
import routes from "./routes";
import store from "./store";
import {
  SET_REDIRECT,
  SET_CURRENT_ERROR,
  SET_ACTIVE_ROUTE,
  SET_AVAILABLE_ROUTES,
  SET_SELECTED_ROOT_ENTITY_BY_REFERENCE,
  SET_ROUTER_QUERY
} from "./store/mutations";
import {
  INIT_SESSION,
  SIGN_OUT,
  CONFIGURE_BRANDING,
  OPEN_ID_AUTHORISE,
  FETCH_HYDRA_LOGIN_CHALLENGE,
  FETCH_HYDRA_CONSENT_CHALLENGE,
  OPEN_ID_CONNECT,
  FIND_OPEN_ID_PROVIDER,
  LOG_EVENT,
  TRY_GET_CLUSTER_FEATURES
} from "./store/actions";
import { FIRST_NAVIGABLE_ROUTE, ROUTER_PARAMS, SESSION_BRANDING_NAME } from "./store/getters";
import config from "./config";

const router = createRouter({
  history: createWebHistory(config.baseRoute),
  routes
});

const loginPaths = [
  "login",
  "cluster-admin-login",
  "reset-password",
  "forgotten-password",
  "activate-account",
  "order-in-progress"
];

const isNotLoggedIn = () => {
  return _.isEmpty(store.state.session);
};

const configureBranding = to => {
  store.dispatch(CONFIGURE_BRANDING, { brandingEntity: to.params.brandingEntity || "encapto" });
};

// eslint-disable-next-line consistent-return
const initQueryParams = route => {
  let { query } = route;
  if (_.isEmpty(query)) {
    const localStorageQueryRaw = window.localStorage[`${route.name}-query`];
    if (localStorageQueryRaw) {
      // if the router query is empty, but we have one saved from before in localstorage,
      // then use that (by routing to the same route, but with query paramaters)
      query = JSON.parse(localStorageQueryRaw);
      return {
        name: route.name,
        params: route.params,
        hash: route.hash,
        query
      };
    }
  }

  // update the state with the query params for this page.
  store.commit(SET_ROUTER_QUERY, { query });
};

// eslint-disable-next-line complexity
const getNextRoute = async toRoute => {
  const isRouteLogin = _.includes(loginPaths, toRoute.name);

  const { brandingEntity, siteReference } = toRoute.params;
  console.log(`Route params: ${JSON.stringify(toRoute.params)}`);

  // don't allow confused URLs like
  // http://.../dashboard/login
  // this happens if someone tries to navigate to the app without a brandingEntity.
  if (store.state.availableRoutes.map(r => r.name).includes(brandingEntity)) {
    return "/error";
  }

  configureBranding(toRoute);

  // if we're already navigating to login,
  // don't set next to login because it would create a route loop.
  const navigateToLogin = isRouteLogin ? undefined : `/${brandingEntity}/login`;

  if (isNotLoggedIn()) {
    console.log("No valid session, try init session");
    await Promise.all([
      store.dispatch(INIT_SESSION, { reference: siteReference }),
      store.dispatch(TRY_GET_CLUSTER_FEATURES)
    ]);
  }

  if (isNotLoggedIn()) {
    console.log("No valid session, need to login");
    if (!isRouteLogin) {
      console.log("Setting redirect", toRoute);
      store.commit(SET_REDIRECT, toRoute);
    }
    return navigateToLogin;
  }

  if (isRouteLogin) {
    console.log("Navigating to first route; is login.", toRoute.name);
    const routerParams = store.getters[ROUTER_PARAMS];
    // If user navigates to alkmi.app and has a session, get branding from there
    // will override the default encapto branding
    const newBrandingEntity = store.getters[SESSION_BRANDING_NAME] || brandingEntity;
    return store.getters[FIRST_NAVIGABLE_ROUTE].path
      .replace(":brandingEntity", newBrandingEntity)
      .replace(":siteName", routerParams.siteName)
      .replace(":siteReference", routerParams.siteReference);
  }
  store.dispatch(LOG_EVENT, { eventName: `visit-${toRoute.name}` });
  store.commit(SET_ACTIVE_ROUTE, toRoute.name);
  // Based on the site reference, set selected root entity
  store.commit(SET_SELECTED_ROOT_ENTITY_BY_REFERENCE, (siteReference || "").toUpperCase());

  console.log("Fetched a valid session", toRoute.name);
  return initQueryParams(toRoute);
};

const handleOidcInitiate = async (to, next) => {
  configureBranding(to);
  if (!to.query.iss) {
    console.error(`Expected query parameter: iss`);
    next("/error");
    return;
  }
  const provider = await store.dispatch(FIND_OPEN_ID_PROVIDER, {
    issuer: to.query.iss,
    subscriptionUuid: to.query.subscriptionUuid
  });
  if (!provider) {
    console.error(`Unknown OpenID Issuer: ${to.query.iss}`);
    next("/error");
    return;
  }
  // eslint-disable-next-line consistent-return
  return store.dispatch(OPEN_ID_CONNECT, {
    provider,
    subscriptionUuid: to.query.subscriptionUuid
  });
};

const handleRoute = async (to, next) => {
  const nextRoute = await getNextRoute(to);
  next(nextRoute);

  // eslint-disable-next-line no-use-before-define
  const redirectAfterSSO = await ssoRoute(to, next);

  if (redirectAfterSSO) {
    window.location.href = redirectAfterSSO;
    return;
  }

  // clear current error when navigate away
  store.commit(SET_CURRENT_ERROR, {});
};

export const routerBeforeEach = async (to, _from, next) => {
  // To Preventing circular reference, pick only necessary data
  console.log(`Routing to: ${JSON.stringify(_.pick(to, ["name", "path", "params"]))}`);
  if (to.name === "signOut") {
    const { brandingEntity } = to.params;
    await store.dispatch(SIGN_OUT);
    next(`/${brandingEntity}/login`);
    store.commit(SET_ACTIVE_ROUTE, "");
    return;
  }
  if (to.name === "error") {
    next();
    return;
  }
  if (to.name === "order-in-progress") {
    configureBranding(to);
    next();
    return;
  }
  if (to.name === "oath") {
    const url = await store.dispatch(OPEN_ID_AUTHORISE, to.query);
    next(url);
    return;
  }
  if (to.name === "oath-initiate") {
    // eslint-disable-next-line consistent-return
    return handleOidcInitiate(to, next);
  }
  if (!to.params.brandingEntity) {
    console.log("No branding provided, defaulting to Encapto branding");
    next("/encapto/login");
    return;
  }

  await handleRoute(to, next);
};

// eslint-disable-next-line consistent-return
const ssoRoute = async (to, next) => {
  if (to.name === "login" && to.query && to.query.login_challenge) {
    return store.dispatch(FETCH_HYDRA_LOGIN_CHALLENGE, to.query.login_challenge).catch(e => {
      console.error(e);
      next("/error");
    });
  }
  if (to.name === "consent") {
    return store.dispatch(FETCH_HYDRA_CONSENT_CHALLENGE, to.query.consent_challenge).catch(e => {
      console.error(e);
      next("/error");
    });
  }
};

export const configureRouter = () => {
  store.commit(SET_AVAILABLE_ROUTES, routes);

  /* istanbul ignore next - test doesn't trigger beforeEach */
  router.beforeEach((to, from, next) => routerBeforeEach(to, from, next));
};

export default router;
