<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="dropdown">
    <!-- FIXME: .native on the @click is required until we are off the migration build of Vue 3 -->
    <!-- https://github.com/vuejs/core/issues/4566 -->
    <alchemy-orb
      v-click-away="away"
      style="overflow: hidden"
      class="cursor-pointer bg-primary-200"
      :size="2"
      dataName="user-profile-dropdown"
      @click.native="toggleDropdown"
      ><img :src="userProfile.profilePic" class="img-fluid" alt="profile"
    /></alchemy-orb>
    <div
      class="dropdown-menu dropdown-menu-right"
      data-name="profile-dropdown-menu"
      :style="{ display: showDropdown ? 'block' : 'none', 'min-width': '200px' }"
    >
      <div class="px-3 py-2 bg-primary-100 user-header">
        <alchemy-heading :level="6" class="mb-0"
          ><small class="text-uppercase"
            ><strong class="text-primary-400">Signed in as</strong></small
          ></alchemy-heading
        >
        <alchemy-heading
          :level="6"
          class="mb-0 font-weight-normal gray-blue-500"
          :manualColors="true"
          >{{ userProfile.name }}</alchemy-heading
        >
      </div>
      <div class="dropdown-divider mt-0 mb-1"></div>
      <div
        class="dropdown-item cursor-pointer"
        data-name="profile-menu-item"
        @click="goToProfilePage"
      >
        <alchemy-icon icon="user" class="mr-2" />Profile
      </div>
      <div
        class="dropdown-item cursor-pointer"
        data-name="feedback-menu-item"
        @click="openFeedbackModal"
      >
        <alchemy-icon icon="heart" class="mr-2" />Give feedback
      </div>
      <div
        v-if="!isImpersonating"
        class="dropdown-item cursor-pointer"
        data-name="signout-menu-item"
        @click="onSignoutClick"
      >
        <alchemy-icon icon="right-from-bracket" class="mr-2" />Sign Out
      </div>
    </div>
    <user-feedback ref="userFeedbackModal" />
  </div>
</template>

<script>
import { mixin as VueClickAway } from "vue3-click-away";
import { iconApi } from "@encapto/alchemy-gui";
import { faSignOutAlt, faUser, faHeart } from "@fortawesome/free-solid-svg-icons";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import { mapGetters } from "vuex";
import { USER_PROFILE, IS_IMPERSONATING, ACTIVE_ROUTE, ROUTER_PARAMS } from "../store/getters";
import router from "../router";
import UserFeedback from "./UserFeedback.vue";

iconApi.library.add(faSignOutAlt, faUser, faHeart, faMap);

export default {
  name: "profile-dropdown",
  mixins: [VueClickAway],
  components: { UserFeedback },
  props: {},
  data() {
    return { showDropdown: false, busy: false };
  },
  computed: {
    ...mapGetters({
      activeRoute: ACTIVE_ROUTE,
      routerParams: ROUTER_PARAMS
    }),
    userProfile() {
      return this.$store.getters[USER_PROFILE];
    },
    isImpersonating() {
      return this.$store.getters[IS_IMPERSONATING];
    }
  },
  methods: {
    away() {
      this.showDropdown = false;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    goToProfilePage() {
      router.push({ name: "profile", params: this.routerParams });
    },
    openFeedbackModal() {
      this.$refs.userFeedbackModal.open();
    },
    onSignoutClick() {
      if (this.busy) {
        return;
      }
      router.push({ name: "signOut", params: this.routerParams });
    }
  }
};
</script>
<style scoped lang="scss">
.user-header {
  margin: calc(-0.25rem + 1px) calc(-0.25rem + 1px) 0 calc(-0.25rem + 1px);
  border-radius: 0.5rem 0.5rem 0 0;
}
</style>
