<template>
  <div
    v-if="isImpersonating"
    class="d-flex justify-content-center bg-warning-400"
    style="border-bottom: 1px solid white"
  >
    <div class="d-flex text-uppercase align-items-center px-5" style="width: fit-content">
      <span>
        <span data-name="impersonator" class="font-weight-bold">{{ impersonator.name }}</span
        >, you are impersonating
        <span data-name="impersonated" class="ml-1 font-weight-bold">{{ userProfile.name }}</span
        >.
      </span>
      <alchemy-button
        data-name="unimpersonate-button"
        class="text-warning-700"
        variant="link"
        style="font-size: 1rem"
        @click="unimpersonate"
      >
        EXIT VIEW
      </alchemy-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_PROFILE, IMPERSONATOR, IS_IMPERSONATING } from "../store/getters";
import { TRY_UNIMPERSONATE } from "../store/actions";
import router from "../router";

export default {
  name: "impersonate-banner",
  computed: {
    ...mapGetters({
      impersonator: IMPERSONATOR,
      userProfile: USER_PROFILE,
      isImpersonating: IS_IMPERSONATING
    })
  },
  methods: {
    unimpersonate() {
      this.$store.dispatch(TRY_UNIMPERSONATE).then(impersonateConfig => {
        router.push(impersonateConfig.route).then(() => router.go());
      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
