<template>
  <alchemy-app-content-host>
    <template v-slot:body>
      <div
        data-name="page-busy"
        class="h-100 m-auto d-flex justify-content-center align-items-center text-center"
      >
        <alchemy-spinner size="5x" />
      </div>
    </template>
  </alchemy-app-content-host>
</template>

<script>
export default {
  name: "page-busy"
};
</script>
