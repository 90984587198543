<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div>
    <alchemy-modal
      dataName="feedbackModel"
      ref="feedbackModel"
      :hideHeader="true"
      :hideFooter="true"
      size="lg"
    >
      <div class="p-2">
        <h6 class="text-gray-blue-500">How would you rate your experience?</h6>
        <div class="mt-3">
          <span
            data-name="smileButton"
            @click="() => (rating = 10)"
            class="cursor-pointer"
            :style="{ color: smileColor }"
          >
            <alchemy-icon size="2x" :icon="['far', 'face-smile']" class="mr-2" />
          </span>
          <span
            data-name="mehButton"
            @click="() => (rating = 5)"
            class="cursor-pointer"
            :style="{ color: mehColor }"
          >
            <alchemy-icon size="2x" :icon="['far', 'face-meh']" class="mr-2" />
          </span>
          <span
            data-name="frownButton"
            @click="() => (rating = 0)"
            class="cursor-pointer"
            :style="{ color: frownColor }"
          >
            <alchemy-icon size="2x" :icon="['far', 'face-frown']" class="mr-2" />
          </span>
        </div>
        <div class="d-flex align-items-end mt-3">
          <div class="w-100">
            <alchemy-textarea
              dataName="feedbackText"
              placeholder="Any additional feedback or suggestions?"
              v-model="text"
              :maxLength="200"
            />
          </div>
          <div class="d-flex align-items-center text-nowrap">
            <alchemy-button
              dataName="cancelFeedbackBtn"
              variant="link"
              class="mx-1"
              @click="submitFeedbackQuietly"
              >No Thanks</alchemy-button
            >
            <alchemy-button
              dataName="sendFeedbackBtn"
              variant="primary"
              class="mx-1"
              @click="submitFeedbackLoudly"
              :disabled="rating === undefined"
              >Send</alchemy-button
            >
          </div>
        </div>
      </div>
    </alchemy-modal>
    <alchemy-modal
      dataName="successModal"
      ref="successModal"
      :hideHeader="true"
      :hideFooter="true"
      :noCloseOnBackdrop="false"
      size="lg"
    >
      <div class="text-center mt-4">
        <alchemy-icon size="3x" icon="heart" color="red" />
        <alchemy-heading :level="5" class="mt-4" color="black">
          Thanks for your feedback</alchemy-heading
        >
        <p class="my-3 text-gray-blue-300">Your feedback will help us improve</p>
        <alchemy-button
          dataName="closeBtn"
          variant="link"
          @click="() => this.$refs.successModal.close()"
          >Close</alchemy-button
        >
      </div>
    </alchemy-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { iconApi } from "@encapto/alchemy-gui";
import { faFaceSmile, faFaceMeh, faFaceFrown } from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { TRY_SUBMIT_USER_FEEDBACK } from "../store/actions";
import { SELECTED_ROOT_ENTITY } from "../store/getters";

iconApi.library.add(faFaceSmile, faFaceMeh, faFaceFrown, faHeart);

export default {
  name: "user-feedback",
  data() {
    return {
      text: "",
      rating: undefined
    };
  },
  computed: {
    ...mapGetters({
      selectedRootEntity: SELECTED_ROOT_ENTITY
    }),
    smileColor() {
      return this.rating === 10 ? "#00A676" : "#ADB5BD";
    },
    mehColor() {
      return this.rating === 5 ? "#F2C94C" : "#ADB5BD";
    },
    frownColor() {
      return this.rating === 0 ? "#E53761" : "#ADB5BD";
    },
    siteReference() {
      return ["site", "company"].includes(this.selectedRootEntity.type)
        ? this.selectedRootEntity.reference
        : undefined;
    }
  },
  methods: {
    open() {
      this.text = "";
      this.rating = undefined;
      this.$refs.feedbackModel.open();
    },
    submitFeedbackLoudly() {
      this.submitUserFeedback({
        text: this.text,
        rating: this.rating,
        page: this.$route.name,
        siteReference: this.siteReference
      });
      this.$refs.feedbackModel.close();
      this.$refs.successModal.open();
    },
    submitFeedbackQuietly() {
      if (this.rating !== undefined) {
        this.submitUserFeedback({
          rating: this.rating,
          page: this.$route.name,
          siteReference: this.siteReference
        });
      }
      this.$refs.feedbackModel.close();
    },
    submitUserFeedback(feedback) {
      this.$store.dispatch(TRY_SUBMIT_USER_FEEDBACK, feedback);
    }
  }
};
</script>

<style scoped>
.fa-face-smile:hover {
  color: #00a676;
}
.fa-face-meh:hover {
  color: #f2c94c;
}
.fa-face-frown:hover {
  color: #e53761;
}
</style>
