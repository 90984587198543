import "./init";
import { createApp } from "vue";
// import _ from "lodash";
// import * as Sentry from "@sentry/vue";
import AlchemyGui, { GuiSkin, fetchApi } from "@encapto/alchemy-gui";
import AlchemyComponents from "@encapto/alchemy-components";
import VueKonva from "vue-konva";
import App from "./App.vue";
import router, { configureRouter } from "./router";
import store from "./store";
import config from "./config";
import { SET_VERSION } from "./store/mutations";
import "@encapto/alchemy-gui/dist/css/alchemy-gui.css";

const app = createApp(App);

app.config.errorHandler = err => {
  store.commit("SET_GLOBAL_ERROR", err);
};

app.use(router);
app.use(store);
app.use(AlchemyGui);
app.use(GuiSkin);
app.use(AlchemyComponents);
app.use(VueKonva);

fetchApi.init(config);

store.commit(SET_VERSION, config.version);

configureRouter();

// const ignoreHosts = ["localhost", "development.alkmi.app", "staging.alkmi.app"];

// Sentry.init({
//   dsn: "https://d1c04efebc754e6aa488a0fb3c5cd9c2@sentry.io/2198326",
//   release: `alchemy@${config.version}`,
//   beforeSend(event, hint) {
//     if (ignoreHosts.includes(window.location.hostname)) {
//       return null;
//     }
//     const status = _.get(hint, "originalException.response.status");
//     if (status === 401 || status === 403) return null;

//     const correlationId = _.get(hint, "originalException.config.headers.x-correlation-id");
//     if (correlationId) {
//       // eslint-disable-next-line no-param-reassign
//       event.extra = { correlationId };
//     }
//     return event;
//   }
// });

app.mount("#app");
