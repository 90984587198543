import _ from "lodash";

const namespaces = {
  accountsWidgetsNamespace: "@encapto/alchemyAccounts",
  brandingNamespace: "@encapto/branding",
  cameraMerakiNamespace: "@encapto/meraki/camera",
  orderWidgetsNamespace: "@encapto/orderManagement",
  clusterAdminUsersWidgetsNamespaces: "@encapto/userList/cluster-admin-users",
  companyUsersWidgetsNamespaces: "@encapto/userList/company-users",
  dashboardMerakiSecurityNamespace: "@encapto/meraki/dashboard-security",
  dashboardMerakiSwitchNamespace: "@encapto/meraki/dashboard-switch",
  dashboardMerakiWirelessNamespace: "@encapto/meraki/dashboard-wireless",
  dashboardMerakiWifiNamespace: "@encapto/meraki/dashboard-wifi",
  dashboardWifiNamespace: "@encapto/wifi/dashboard",
  elasticsearchWidgetsNamespace: "@encapto/elasticsearch",
  clusterAdminInventoryWidgetsNamespace: "@encapto/alchemyInventory/cluster-admin-inventory",
  distributorInventoryWidgetsNamespace: "@encapto/alchemyInventory/distributor-inventory",
  serviceProviderInventoryWidgetsNamespace: "@encapto/alchemyInventory/service-provider-inventory",
  manageWifiNamespace: "@encapto/wifi/manage-wifi",
  networkingMerakiNamespace: "@encapto/meraki/networking",
  sdWanMerakiNamespace: "@encapto/meraki/sd-wan",
  seatWidgetsNamespace: "@encapto/alchemySeat",
  securityMerakiNamespace: "@encapto/meraki/security",
  securityUmbrellaNamespace: "@encapto/cisco-umbrella/security",
  usageWidgetsNamespace: "@encapto/alchemyUsage",
  userWidgetsNamespace: "@encapto/userManagement",
  wifiMerakiNamespace: "@encapto/meraki/wifi",
  wifiNamespace: "@encapto/wifi/wifi"
};

const routeVisibility = {
  admin: {
    composite: true,
    candidateTypes: [],
    functions: ["merchant-admin-list-users"]
  },
  adminRole: {
    composite: true,
    candidateTypes: [],
    functions: [
      "merchant-admin-get-role",
      "sp-admin-get-role",
      "distributor-admin-get-role",
      "cluster-admin-get-role"
    ]
  },
  adminAccess: {
    composite: true,
    candidateTypes: [],
    functions: [
      "merchant-admin-get-access-group",
      "sp-admin-get-access-group",
      "distributor-admin-get-access-group",
      "cluster-admin-get-access-group"
    ]
  },
  customerRole: {
    candidateTypes: [],
    functions: ["sp-customers-get-role"]
  },
  customerAccess: {
    candidateTypes: [],
    functions: ["sp-customers-get-access-group"]
  },
  blueprint: {
    candidateTypes: ["sp-blueprint"],
    functions: ["sp-blueprint-get-blueprint"]
  },
  "blueprint-provisioning": {
    candidateTypes: ["sp-blueprint"],
    functions: ["sp-blueprint-get-blueprint"]
  },
  "blueprint-deprovisioning": {
    candidateTypes: ["sp-blueprint"],
    functions: ["sp-blueprint-get-blueprint"]
  },
  "blueprint-configuration": {
    candidateTypes: ["sp-blueprint"],
    functions: ["sp-blueprint-get-blueprint"]
  },
  quotes: {
    candidateTypes: ["sp-quote-group"],
    functions: ["sp-quote-list-quotes"]
  },
  quote: {
    candidateTypes: [], // ["quote"] The quote will exist but the session would need refreshing.
    functions: ["sp-quote-get-quote"]
  },
  bom: {
    candidateTypes: [], // ["quote"] When creating the first quote this test breaks as quote doesn't exist.
    functions: ["sp-quote-save-quote"]
  },
  fulfilment: {
    candidateTypes: [], // ["quote"], The quote will exist but the session would need refreshing.
    functions: ["sp-fulfilment-order-create-inventory-order"]
  },
  camera: {
    candidateTypes: ["meraki-camera-device"],
    functions: [
      "merchant-camera-summary-meraki-camera-get-overview",
      "merchant-camera-feed-meraki-camera-get-zones",
      "merchant-camera-history-meraki-camera-get-overview"
    ]
  },
  "cluster-admin": {
    candidateTypes: ["alchemy-cluster-admin-user-group"],
    functions: ["cluster-admin-list-users"]
  },
  "cluster-management": {
    candidateTypes: ["alchemy-cluster-service-providers"],
    functions: ["admin-manage-list-all-users"]
  },
  "cluster-network-support": {
    candidateTypes: ["alchemy-cluster-oauth-client-group"],
    functions: ["admin-tech-support-list-oauth-clients"]
  },
  "cluster-service-providers": { candidateTypes: [], functions: ["list-service-providers"] },
  "cluster-distributors": {
    candidateTypes: [],
    functions: ["admin-distributors-list-distributors"]
  },
  "entity-visualization": {
    candidateTypes: [],
    functions: ["admin-manage-entity-visualization-query"]
  },
  "distributor-admin": {
    candidateTypes: ["distributor-user-group"],
    functions: ["distributor-admin-list-users"]
  },
  customers: { candidateTypes: [], functions: ["sp-customers-table-list-companies"] },
  "customer-detail": {
    candidateTypes: [],
    functions: ["sp-customers-get-company-entity"]
  },
  dashboard: {
    candidateTypes: [
      "encapto-wifi",
      "meraki-security-device",
      "public-ssid",
      "meraki-combined-network"
    ],
    functions: [
      "merchant-dashboard-wifi-report-by-time-newvsreturn-v2",
      "merchant-dashboard-wifi-report-by-campaign-platform",
      "merchant-dashboard-wifi-report-summaries-details",
      "merchant-dashboard-wifi-create-reporting-session",
      "merchant-dashboard-meraki-security-get-sdwan-devices",
      "merchant-dashboard-wifi-meraki-get-network-clients",
      "merchant-dashboard-network-status-meraki-get-wireless-ssids",
      "merchant-dashboard-network-status-meraki-list-site-wireless-device-statuses",
      "merchant-dashboard-network-status-meraki-list-site-switch-device-statuses",
      "merchant-dashboard-network-status-meraki-get-network-topology",
      "merchant-dashboard-network-summary-meraki-get-network-clients-bandwidth-usage-history",
      "merchant-dashboard-network-summary-meraki-get-network-traffic",
      "merchant-dashboard-network-summary-meraki-get-network-channel-utilisation",
      "merchant-dashboard-events-alerts-meraki-get-network-events",
      "merchant-dashboard-events-alerts-meraki-network-alerts-history"
    ]
  },
  "global-dashboard": {
    composite: true,
    candidateTypes: [
      "encapto-wifi",
      "meraki-configuration",
      "public-ssid",
      "company-cisco-umbrella-configuration"
    ],
    functions: [
      "merchant-dashboard-network-status-meraki-list-security-device-statuses",
      "merchant-dashboard-network-status-meraki-list-wireless-device-statuses",
      "merchant-dashboard-network-status-meraki-list-switch-device-statuses",
      "merchant-dashboard-network-status-meraki-list-camera-device-statuses",
      "merchant-dashboard-wifi-create-reporting-session",
      "merchant-dashboard-security-cisco-umbrella-deployment-status-report",
      "merchant-dashboard-network-summary-meraki-get-organisation-clients-bandwidth-usage-history",
      "merchant-dashboard-network-summary-meraki-get-organisation-top-appliances",
      "merchant-dashboard-network-summary-meraki-get-organisation-top-devices",
      "merchant-dashboard-network-summary-meraki-get-top-switches-by-energy-usage",
      "merchant-dashboard-network-summary-meraki-get-top-ssids-by-usage",
      "merchant-dashboard-network-summary-meraki-get-top-clients-by-usage",
      "merchant-dashboard-network-summary-meraki-get-organisation-clients-overview"
    ]
  },
  "global-security": {
    composite: true,
    candidateTypes: ["company-cisco-umbrella-configuration", "company"],
    functions: [
      "merchant-security-list-sites",
      "merchant-security-cisco-umbrella-list-company-roaming-clients"
    ]
  },
  "company-manage": {
    composite: true,
    candidateTypes: [],
    functions: [
      "merchant-manage-sites-list-company-sites",
      "merchant-manage-inventory-company-list-seats"
    ]
  },
  catalogue: {
    candidateTypes: [],
    functions: [
      "sp-inventories-get-inventory-definitions",
      "admin-inventories-get-inventory-definitions",
      "distributor-inventories-get-inventory-definitions"
    ]
  },
  inventory: {
    candidateTypes: [],
    functions: [
      "sp-inventories-get-inventory-definitions",
      "admin-inventories-get-inventory-definitions",
      "distributor-inventories-get-inventory-definitions"
    ]
  },
  "inventory-detail": {
    candidateTypes: [],
    functions: [
      "sp-inventories-get-inventory-definitions",
      "admin-inventories-get-inventory-definitions",
      "distributor-inventories-get-inventory-definitions"
    ]
  },
  "assisted-sales": {
    candidateTypes: [],
    functions: ["sp-inventory-metadata-list-inventory-metadata"]
  },
  bundles: {
    candidateTypes: [],
    functions: [
      "sp-inventories-get-inventory-definitions",
      "admin-inventories-get-inventory-definitions",
      "distributor-inventories-get-inventory-definitions"
    ]
  },
  bundle: {
    candidateTypes: [],
    functions: [
      "sp-inventories-get-inventory-definitions",
      "admin-inventories-get-inventory-definitions",
      "distributor-inventories-get-inventory-definitions"
    ]
  },
  slots: {
    candidateTypes: [],
    functions: [
      "sp-inventories-list-slots-with-definitions",
      "admin-inventories-list-slots-with-definitions",
      "distributor-inventories-list-slots-with-definitions"
    ]
  },
  slot: {
    candidateTypes: [],
    functions: [
      "sp-inventories-list-slots-with-definitions",
      "admin-inventories-list-slots-with-definitions",
      "distributor-inventories-list-slots-with-definitions"
    ]
  },
  "catalogue-settings": {
    candidateTypes: [],
    functions: [
      "sp-inventory-questions-list-inventory-questions",
      "sp-inventory-metadata-list-inventory-metadata",
      "distributor-inventory-questions-list-inventory-questions",
      "distributor-inventory-metadata-list-inventory-metadata",
      "admin-inventory-metadata-list-inventory-metadata"
    ]
  },
  "manage-wifi": { candidateTypes: ["encapto-wifi"], functions: ["sp-manage-wifi-create-session"] },
  networking: {
    candidateTypes: ["meraki-switch-device"],
    functions: ["merchant-networking-list-network-switch-device-with-status"]
  },
  "networking-detail": {
    candidateTypes: [],
    functions: ["merchant-networking-meraki-get-switch-ports"]
  },
  orders: {
    composite: true,
    candidateTypes: [],
    functions: [
      "admin-orders-list-cluster-orders",
      "distributor-orders-list-distributor-orders",
      "sp-orders-list-orders",
      "merchant-orders-list-merchant-orders"
    ]
  },
  sdwan: {
    candidateTypes: ["meraki-security-device"],
    functions: [
      "merchant-sdwan-meraki-get-device-lan-port-information",
      "merchant-sdwan-meraki-get-device-details",
      "merchant-sdwan-meraki-get-vpn-status"
    ]
  },
  firewall: {
    candidateTypes: [
      "meraki-wireless-device",
      "meraki-security-device",
      "private-ssid",
      "public-ssid",
      "guest-ssid"
    ],
    functions: [
      "merchant-firewall-firewall-meraki-get-l7-firewall-rules",
      "merchant-firewall-shaping-meraki-get-traffic-shaping-rules",
      "merchant-firewall-adult-content-meraki-get-wireless-network-ssid-settings"
    ]
  },
  security: {
    candidateTypes: ["cisco-umbrella"],
    functions: ["merchant-security-cisco-umbrella-list-site-roaming-clients"]
  },
  "sp-admin": {
    candidateTypes: [],
    functions: [
      "sp-admin-list-users",
      "sp-admin-get-usage-reports",
      "sp-admin-get-branding-entity",
      "sp-admin-integrations-list-sp-configurations",
      "sp-admin-billing-billing-get-usage-dynamic-pie-chart",
      "sp-admin-sso-list-saml-idp"
    ]
  },
  "sp-dashboard": {
    candidateTypes: [],
    functions: ["sp-dashboard-calculate-created", "sp-dashboard-order-daily-license-status-pie"]
  },
  usage: { candidateTypes: [], functions: ["calculate-created", "billing-list-usage-points"] },
  wifi: {
    candidateTypes: ["encapto-wifi", "meraki-wireless-device", "private-ssid", "guest-ssid"],
    functions: [
      "merchant-wifi-meraki-get-meraki-auth-users",
      "merchant-wifi-meraki-get-guest-wireless-network-ssid-settings",
      "merchant-wifi-meraki-get-wireless-network-ssid-settings",
      "merchant-wifi-get-portal-groups-portals",
      "merchant-wifi-get-campaigns",
      "merchant-wifi-get-sites-meraki-networks-survey",
      "merchant-wifi-list-available-reports",
      "merchant-wifi-get-terms",
      "merchant-wifi-get-sites-meraki-networks-public-hotspot-settings",
      "merchant-wifi-get-sites-meraki-networks-trading-hours",
      "merchant-wifi-get-auths-public-access-settings",
      "merchant-wifi-get-auths-website-redirect"
    ]
  }
};

const debugConsole = (debug, ...msgNStuff) => debug && console.log(...msgNStuff);

const checkRouteExists = ({ route, routeName }) => {
  if (route) return true;
  console.warn("Missing route definition", routeName);
  return false;
};

const checkComposite = ({ isComposite, routeName, route, isMerchantUser, debug }) => {
  if (!isMerchantUser) return true; // Composite check only applies to merchants.
  if (!!isComposite === !!route.composite) return true;
  debugConsole(debug, "failed composite check.", routeName, route);
  return false;
};

/* istanbul ignore next - there are no feature flags currently */
const checkFeatureToggles = ({ route, getters, routeName, debug }) => {
  if (!route.feature || getters[route.feature]) return true;
  debugConsole(debug, "failed feature check.", routeName, route);
  return false;
};

const checkEntityTypes = ({ availableEntityTypes, route, routeName, debug }) => {
  if (!route.candidateTypes.length) return true;
  if (availableEntityTypes.some(type => route.candidateTypes.includes(type))) return true;
  debugConsole(debug, "failed candidate types check.", routeName, route, availableEntityTypes);
  return false;
};

const checkFunctionExists = ({ route, sessionFunctions, routeName, debug }) => {
  if (route.functions.some(fnName => !!sessionFunctions[fnName])) return true;
  debugConsole(debug, "failed function check.", routeName, route);
  return false;
};

const checkFunctionTypesMatch = ({
  owningEntity,
  availableEntityTypes,
  route,
  sessionFunctions,
  debug,
  routeName
}) => {
  if (!route.candidateTypes.length) return true; // no types defined nothing to check.
  const entityTypes = [...availableEntityTypes, _.get(owningEntity, "type"), "*"];
  const matchingFunction = route.functions.some(name => {
    // Function not in session.
    if (!sessionFunctions[name]) return false;

    // We have both functions and types defined check that they marry up.
    const fn = sessionFunctions[name];
    const fnEntityTypes = fn.entityType
      // Meraki network functions often still rely on devices. Adding device type
      .replace(/meraki-(.*)-network/, "meraki-$1-network,meraki-$1-device")
      .split(",")
      .map(t => t.trim());

    return fnEntityTypes.some(type => entityTypes.includes(type));
  });
  if (matchingFunction) return true;
  debugConsole(debug, "failed function type check.", routeName, route);
  return false;
};

const calculateVisibleRoutes = ({
  sessionRoutes,
  isMerchantUser,
  isComposite,
  owningEntity,
  availableEntityTypes,
  sessionFunctions,
  getters,
  debug = false
}) =>
  sessionRoutes.filter(routeName => {
    const route = routeVisibility[routeName];
    debugConsole(debug, "isVisible route:", routeName, route);
    return [
      checkRouteExists,
      checkComposite,
      checkFeatureToggles,
      checkEntityTypes,
      checkFunctionExists,
      checkFunctionTypesMatch
    ].every(checkFn =>
      checkFn({
        route,
        routeName,
        isMerchantUser,
        isComposite,
        owningEntity,
        availableEntityTypes,
        sessionFunctions,
        getters,
        debug
      })
    );
  });

export {
  namespaces,
  routeVisibility,
  checkComposite,
  checkRouteExists,
  checkFunctionExists,
  calculateVisibleRoutes,
  debugConsole
};
