import { storeUtils } from "@encapto/alchemy-gui";
import { setSentryScope } from "../lib-api/utils";

export const SET_VERSION = "SET_VERSION";
export const SET_SESSION = "SET_SESSION";
export const SET_REDIRECT = "SET_REDIRECT";
export const SET_BRANDING = "SET_BRANDING";
export const SET_BRANDING_ENTITY = "SET_BRANDING_ENTITY";
export const SET_OPEN_ID_PROVIDERS = "SET_OPEN_ID_PROVIDERS";
export const SET_CURRENT_ERROR = "SET_CURRENT_ERROR";
export const SET_REPORT_PERIOD = "SET_REPORT_PERIOD";
export const SET_REPORT_CUSTOM_DATES = "SET_REPORT_CUSTOM_DATES";
export const SET_ACTIVE_ROUTE = "SET_ACTIVE_ROUTE";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_AVAILABLE_ROUTES = "SET_AVAILABLE_ROUTES";
export const SET_SELECTED_ROOT_ENTITY = "SET_SELECTED_ROOT_ENTITY";
export const SET_SELECTED_ROOT_ENTITY_BY_REFERENCE = "SET_SELECTED_ROOT_ENTITY_BY_REFERENCE";
export const SET_AVAILABLE_ENTITY_TYPES = "SET_AVAILABLE_ENTITY_TYPES";
export const SET_AVAILABLE_OWNER_TYPES = "SET_AVAILABLE_OWNER_TYPES";
export const SET_PAGE_VISIBLE = "SET_PAGE_VISIBLE";

export const SET_LOGIN_CHALLENGE = "SET_LOGIN_CHALLENGE";
export const SET_OAUTH_CLIENT = "SET_OAUTH_CLIENT";
export const SET_SELECTED_OAUTH_ENTITY = "SET_SELECTED_OAUTH_ENTITY";
export const SET_REDIRECTING = "SET_REDIRECTING";

export const SET_ROUTER_QUERY = "SET_ROUTER_QUERY";
export const SET_SUPPORT_LINKS = "SET_SUPPORT_LINKS";
export const SET_CLUSTER_FEATURES = "SET_CLUSTER_FEATURES";
export const SET_ANNOUNCEMENTS = "SET_ANNOUNCEMENTS";
export const CLEAR_ANNOUNCEMENT = "CLEAR_ANNOUNCEMENT";

const { createSimpleMutation, SET_GLOBAL_ERROR } = storeUtils;

export const mutations = {
  [SET_VERSION]: (state, payload) => {
    state.info.version = payload;
  },
  [SET_SESSION](state, session) {
    state.session = session;
    setSentryScope(session);
  },
  [SET_REPORT_PERIOD](state, period) {
    state.dashboard.period = period;
  },
  [SET_REPORT_CUSTOM_DATES](state, customDates) {
    state.dashboard.customDates = { ...customDates };
  },
  ...createSimpleMutation(SET_REDIRECT, "redirect"),
  ...createSimpleMutation(SET_BRANDING, "branding"),
  ...createSimpleMutation(SET_BRANDING_ENTITY, "brandingEntity"),
  ...createSimpleMutation(SET_OPEN_ID_PROVIDERS, "openIdProviders"),
  ...createSimpleMutation(SET_CURRENT_ERROR, "currentError"),
  [SET_USER_PROFILE](state, userProfile) {
    state.session.user = userProfile;
  },
  ...createSimpleMutation(SET_AVAILABLE_ROUTES, "availableRoutes"),
  ...createSimpleMutation(SET_LOGIN_CHALLENGE, "loginChallenge"),
  ...createSimpleMutation(SET_OAUTH_CLIENT, "oauthClient"),
  ...createSimpleMutation(SET_SELECTED_OAUTH_ENTITY, "oauthEntity"),
  [SET_GLOBAL_ERROR](state, e) {
    // Please use the spread operator to add new items to arrays to follow immutable patterns
    // https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns#inserting-and-removing-items-in-arrays
    console.error(e);
    state.currentError = { ...e };
    state.errors = [...state.errors, { ...e }];
  },
  ...createSimpleMutation(SET_ACTIVE_ROUTE, "activeRoute"),
  ...createSimpleMutation(SET_SELECTED_ROOT_ENTITY, "selectedRootEntity"),
  ...createSimpleMutation(SET_SELECTED_ROOT_ENTITY_BY_REFERENCE, "routeRootReference"),

  ...createSimpleMutation(SET_REDIRECTING, "redirecting"),
  [SET_PAGE_VISIBLE](state, { page, visible }) {
    state[`${page}_VISIBLE`] = visible;
  },
  [SET_ROUTER_QUERY](state, { query }) {
    state.routerQuery = query;
  },
  ...createSimpleMutation(SET_SUPPORT_LINKS, "supportLinks"),
  ...createSimpleMutation(SET_AVAILABLE_ENTITY_TYPES, "availableEntityTypes"),
  ...createSimpleMutation(SET_CLUSTER_FEATURES, "clusterFeatures"),
  ...createSimpleMutation(SET_AVAILABLE_OWNER_TYPES, "availableOwnerTypes"),
  ...createSimpleMutation(SET_ANNOUNCEMENTS, "announcements"),
  [CLEAR_ANNOUNCEMENT](state, announcement) {
    state.announcements = state.announcements.filter(a => a.uuid !== announcement.uuid);
  }
};
