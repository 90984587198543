import * as Sentry from "@sentry/vue";
import _ from "lodash";

const setSentryScope = session => {
  const scope = Sentry.getCurrentScope();
  const uuid = _.get(session, "user.uuid");
  const user = uuid ? { id: uuid } : null;
  scope.setUser(user);
};

const toSeoFriendlyUrl = url =>
  url
    .toString() // Convert to string
    .normalize("NFD") // Change diacritics
    .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
    .replace(/\s+/g, "-") // Change whitespace to dashes
    .toLowerCase() // Change to lowercase
    .replace(/&/g, "-and-") // Replace ampersand
    .replace(/[^a-z0-9-]/g, "") // Remove anything that is not a letter, number or dash
    .replace(/-+/g, "-") // Remove duplicate dashes
    .replace(/^-*/, "") // Remove starting dashes
    .replace(/-*$/, ""); // Remove trailing dashes

// eslint-disable-next-line import/prefer-default-export
export { setSentryScope, toSeoFriendlyUrl };
