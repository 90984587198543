<template>
  <div>
    <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
    <a
      v-if="supportLink"
      data-name="support-link"
      :href="supportLink"
      target="_blank"
      rel="noopener noreferrer"
    >
      <alchemy-orb style="overflow: hidden" title="Help" :size="1" dataName="support-link-orb">
        <alchemy-icon :icon="['far', 'circle-question']" />
      </alchemy-orb>
    </a>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { iconApi } from "@encapto/alchemy-gui";
import { TRY_GET_SUPPORT_LINKS } from "../store/actions";
import { ACTIVE_ROUTE, SUPPORT_LINKS } from "../store/getters";

iconApi.library.add(faCircleQuestion);

export default {
  name: "support-link",
  mounted() {
    this.$store.dispatch(TRY_GET_SUPPORT_LINKS);
  },
  computed: {
    ...mapState({
      userType: state => state.session.type
    }),
    ...mapGetters({
      activeRoute: ACTIVE_ROUTE,
      supportLinks: SUPPORT_LINKS
    }),
    supportLink() {
      return _.get(this.supportLinks, [this.userType, this.activeRoute]);
    }
  }
};
</script>

<style scoped lang="scss">
a:link,
a:visited {
  color: inherit;
}
</style>
