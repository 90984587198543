<template>
  <div :class="{ 'vh-100': redirecting }">
    <alchemy-app-host v-bind:class="{ login: !loggedIn }" v-if="!redirecting">
      <template v-slot:header>
        <impersonate-banner />
        <app-header
          v-if="showAppContent"
          ref="appHeader"
          :navCompact="navCompact"
          :activeRouteName="activeRouteName"
          @toggleMobileMenuVisible="
            () => {
              mobileMenuVisible = !mobileMenuVisible;
            }
          "
        />
      </template>

      <template v-slot:navbar>
        <alchemy-nav
          v-if="showAppContent"
          ref="navBar"
          :navItems="navItems"
          :mobileMenuVisible="mobileMenuVisible"
        />
      </template>

      <template v-slot:announcement v-if="showAnnouncement">
        <announcement />
      </template>

      <template v-slot:body>
        <msp-onboarding-wizard
          v-if="isFirstTime"
          ref="mspOnboardingWizard"
          @getStarted="getStarted"
        />
        <page-busy v-show="showPageBusy" />
        <router-view v-show="showRouterView" data-name="routerView" />
      </template>

      <template v-slot:footer v-if="showAppContent">
        <app-footer />
      </template>
    </alchemy-app-host>

    <div v-else class="d-flex h-100 align-items-center justify-content-center">
      <alchemy-spinner size="3x" />
      <alchemy-heading :level="4" color="gray-blue" class="ml-2 mt-2"
        >Redirecting...</alchemy-heading
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import { iconApi } from "@encapto/alchemy-gui";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { MspOnboardingWizard } from "@encapto/alchemy-onboarding-widgets";
import {
  LOGGED_IN,
  OWNING_ENTITY,
  ERROR_STATUS_CODE,
  BRANDING,
  ROUTER_PARAMS,
  ACTIVE_ROUTE,
  SELECTED_ROOT_ENTITY,
  VISIBLE_NAV_ITEMS,
  PAGE_BUSY,
  ALL_SITES_VISIBLE,
  IS_COMPOSITE_ENTITY
} from "./store/getters";

import {
  CLEAR_SESSION,
  CONFIGURE_UPDATED_BRANDING,
  REFRESH_SESSION,
  TRY_GET_AVAILABLE_ENTITY_TYPES,
  TRY_GET_AVAILABLE_OWNER_TYPES
} from "./store/actions";
import { SET_SELECTED_ROOT_ENTITY } from "./store/mutations";
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";
import PageBusy from "./components/PageBusy.vue";
import ImpersonateBanner from "./components/ImpersonateBanner.vue";
import Announcement from "./components/Announcement.vue";
import AlchemyNav from "./components/alchemy-nav.vue";

import router from "./router";

iconApi.library.add(faBars);

export default {
  name: "app",
  components: {
    AppHeader,
    AppFooter,
    PageBusy,
    ImpersonateBanner,
    Announcement,
    MspOnboardingWizard,
    AlchemyNav
  },
  data() {
    return {
      mobileMenuVisible: false,
      // eslint-disable-next-line complexity
      navigateFirstSafeRoute: newItems => {
        const activeRouteVisible = !!newItems.find(({ active }) => active);

        if (!activeRouteVisible && this.loggedIn && !this.pageBusy) {
          const firstRoute = _.get(newItems, [0, "name"]) || "no-access";
          console.log(
            `The current route '${this.activeRoute}' is no longer visible. Routing to ${firstRoute}`
          );
          // eslint-disable-next-line consistent-return
          return router
            .push({ name: firstRoute, params: this.routerParams })
            .catch(err => console.log(err));
        }
        return undefined;
      },
      debouncedNavigateSafeRoute: _.debounce(a => this.navigateFirstSafeRoute(a), 200, {
        trailing: true
      })
    };
  },
  watch: {
    errorStatusCode(newVal, oldVal) {
      if (newVal === 401 && oldVal !== 401) {
        this.$store.dispatch(CLEAR_SESSION).then(() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        });
      }
    },
    branding(newVal) {
      this.$skin.remove();
      this.$skin.apply(newVal);
    },
    pageBusy(newVal) {
      if (!newVal) {
        // navItems may not update after page has loaded
        // so debouncedNavItems may not get called.
        this.debouncedNavigateSafeRoute(this.navItems);
      }
    },
    selectedRootEntity(newE, oldE) {
      if (_.get(oldE, "uuid") !== _.get(newE, "uuid")) {
        console.log(
          "selectedRootEntity changed; loading entity types",
          _.get(oldE, "name"),
          "->",
          _.get(newE, "name")
        );
        this.$store.dispatch(TRY_GET_AVAILABLE_ENTITY_TYPES).then(() => {
          this.debouncedNavigateSafeRoute(this.navItems); // stops login widget from remaining on the page
        });
      }
    },
    owningEntity(newE, oldE) {
      if (oldE !== newE) {
        this.$store.dispatch(TRY_GET_AVAILABLE_OWNER_TYPES);
      }
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: LOGGED_IN,
      errorStatusCode: ERROR_STATUS_CODE,
      branding: BRANDING,
      routerParams: ROUTER_PARAMS,
      navItems: VISIBLE_NAV_ITEMS,
      activeRoute: ACTIVE_ROUTE,
      selectedRootEntity: SELECTED_ROOT_ENTITY,
      owningEntity: OWNING_ENTITY,
      showAllSites: ALL_SITES_VISIBLE,
      isComposite: IS_COMPOSITE_ENTITY,
      pageBusy: PAGE_BUSY
    }),
    ...mapState({
      redirecting: state => state.redirecting,
      showLoginScreen: state => state.showLoginScreen
    }),
    navCompact() {
      return _.get(this, "$refs.navBar.isCompact", false);
    },
    activeRouteName() {
      const mainMenuPage = this.navItems.find(nav => nav.active);
      const mainPageName = _.get(mainMenuPage, "label", "");
      const childPage = _.get(mainMenuPage, "children", []).find(c => c.active);
      const childPageName = _.get(childPage, "label");
      return [mainPageName, childPageName].filter(Boolean).join(" > ");
    },
    currentRouteName() {
      return this.$route.name;
    },
    isFirstTime() {
      // msp onboarding if first time status is not done
      const firstTimeStatus = _.get(this.selectedRootEntity, "configuration.firstTimeStatus");
      return (
        this.loggedIn &&
        ["inProgress", "notStarted", "viewOnboardingVideos"].includes(firstTimeStatus)
      );
    },
    showAppContent() {
      return this.loggedIn && !this.isFirstTime;
    },
    showPageBusy() {
      return (
        (this.showAppContent && this.pageBusy) ||
        (this.showAppContent && this.currentRouteName === "login")
      );
    },
    showRouterView() {
      return !this.isFirstTime && !this.showPageBusy;
    },
    showAnnouncement() {
      return this.showAppContent && !this.pageBusy;
    }
  },
  methods: {
    getStarted() {
      // branding may be updated
      this.$store.dispatch(CONFIGURE_UPDATED_BRANDING, {
        brandingEntity: this.$store.state.brandingEntity
      });
      // refresh the session from the db.
      return this.$store.dispatch(REFRESH_SESSION).then(() => {
        // need to set the root entity as status is now "done".
        this.$store.commit(SET_SELECTED_ROOT_ENTITY, this.$store.state.session.rootEntity);
      });
    }
  }
};
</script>

<style lang="scss">
.login {
  .alchemy-content-host {
    height: 100vh;
    > .alchemy-scroll-area {
      margin: 0 !important;
      padding: 0 !important;
      height: 100vh;
    }
  }
}
.app-header .btn-alchemy-link {
  color: inherit !important;
  padding: 0;
  margin: 0;
  font-weight: 400;
}
</style>
