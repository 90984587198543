<template>
  <div data-name="app-footer" class="d-lg-flex justify-content-end align-items-center">
    <small class="text-muted text-uppercase flex-fill" style="font-size: 70%">
      <strong data-name="copyright">© Copyright {{ new Date().getFullYear() }}</strong> - All
      rights reserved - Encapto Pty Ltd
    </small>
    <div class="flex-fill d-lg-flex ml-lg-auto py-1 align-items-center">
      <a
        class="d-flex flex-fill align-items-center justify-content-lg-end"
        href="https://www.encapto.com/contact-us"
        target="_blank"
        rel="noopener noreferrer"
      >
        <small class="text-muted mr-1">powered by</small>
        <img
          class="d-inline-block"
          alt="logo"
          v-on:load="objectFitPoly()"
          style="object-fit: contain; max-width: 100%; min-height: 18px; max-height: 18px"
          title="Encapto logo"
          :src="EncaptoLogo"
        />
      </a>
    </div>
  </div>
</template>

<script>
import objectFitImages from "object-fit-images";

export default {
  name: "app-footer",
  data() {
    return {
      EncaptoLogo:
        "https://storage.googleapis.com/alchemy-cdn-bucket/images/smb/encapto-logo-d9d7fc09ab5c72a6846efdb3c2173d05.svg"
    };
  },
  methods: {
    objectFitPoly() {
      // polyfill for IE11 and older browsers for object-fit
      objectFitImages();
    }
  }
};
</script>

<style lang="scss" scoped></style>
