<template>
  <alchemy-app-content-host>
    <template v-slot:body>
      <div
        id="resetPasswordHost"
        v-if="branding"
        class="py-4 d-flex flex-column h-100"
        :style="backgroundStyle"
      >
        <div class="container">
          <alchemy-reset-lost-password-widget
            storeModule="alchemyLogin"
            @backToLogin="backToLogin"
            @success="gotoDashboard"
            :passwordResetToken="passwordResetToken"
          />
        </div>
      </div>
    </template>
  </alchemy-app-content-host>
</template>

<script>
import { mapGetters } from "vuex";
import { AlchemyResetLostPasswordWidget } from "@encapto/alchemy-login-widget";
import { SET_SESSION } from "../store/mutations";
import { BRANDING, REDIRECT_URL, DEFAULT_LOGIN_BACKGROUND } from "../store/getters";

export default {
  name: "reset-password",
  components: {
    AlchemyResetLostPasswordWidget
  },
  computed: {
    ...mapGetters({
      branding: BRANDING,
      redirectUrl: REDIRECT_URL,
      defaultLoginBackground: DEFAULT_LOGIN_BACKGROUND
    }),
    backgroundStyle() {
      return `background-image: url(${
        this.branding.loginBackgroundUrl || this.defaultLoginBackground
      }); background-repeat: no-repeat; background-size: cover`;
    },
    passwordResetToken() {
      const { passwordResetToken } = this.$route.params;
      return passwordResetToken;
    }
  },
  methods: {
    backToLogin() {
      this.$router.push({ name: "login" });
    },
    gotoDashboard(event) {
      const session = event.data;
      console.log("Session!", session);
      // save session to store
      this.$store.commit(SET_SESSION, session);
      this.$router.push(this.redirectUrl);
    }
  }
};
</script>

<style lang="scss" scoped>
#resetPasswordHost {
  justify-content: flex-start;
  .container {
    max-width: 540px;
  }
}
@media screen and (min-width: 540px) {
  #resetPasswordHost {
    justify-content: center;
  }
}
</style>
