<template>
  <svg
    width="1em"
    viewBox="0 0 17 18"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3562 4.39519L12.8839 5.53466L14.0964 5.76113C15.238 5.97435 16 6.88074 16 7.85714C16 8.9682 15.0012 10 13.6 10H10.4842H6.375H3.825C2.19006 10 1 8.79141 1 7.46429C1 6.41004 1.73458 5.44588 2.86455 5.07844L3.58483 4.84422L3.55451 4.08742C3.55172 4.01778 3.55 3.9715 3.55 3.92857C3.55 2.38292 4.92866 1 6.8 1C8.04065 1 9.08833 1.62159 9.63595 2.49877L10.164 3.34459L11.0113 2.81898C11.2582 2.66588 11.5646 2.57143 11.9 2.57143C12.831 2.57143 13.45 3.25211 13.45 3.92857C13.45 4.09124 13.4169 4.24871 13.3562 4.39519Z"
      stroke-width="2"
    />
    <circle cx="1.84615" cy="15.8462" r="1.09615" stroke-width="1.5" />
    <circle cx="14.8462" cy="15.8462" r="1.09615" stroke-width="1.5" />
    <circle cx="8.84615" cy="15.8462" r="1.09615" stroke-width="1.5" />
    <line x1="2.5" y1="12.5" x2="14.5" y2="12.5" stroke-linecap="round" />
    <line x1="8.5" y1="11" x2="8.5" y2="12" />
    <line x1="2.5" y1="12.5" x2="2.5" y2="13.5" stroke-linecap="round" />
    <line x1="14.5" y1="12.5" x2="14.5" y2="13.5" stroke-linecap="round" stroke-linejoin="bevel" />
    <line x1="8.5" y1="12.5" x2="8.5" y2="13.5" stroke-linecap="round" stroke-linejoin="bevel" />
  </svg>
</template>
<script>
export default {
  name: "sd-wan-svg-icon"
};
</script>
