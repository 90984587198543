<template>
  <alchemy-app-content-host>
    <template v-slot:body>
      <div
        id="activateAccountHost"
        v-if="branding"
        class="py-4 d-flex flex-column h-100"
        :style="backgroundStyle"
      >
        <div class="container">
          <alchemy-activate-account-widget
            storeModule="alchemyLogin"
            @backToLogin="backToLogin"
            @success="gotoDashboard"
            :activateEmail="activateEmail"
            :activateToken="activateToken"
          />
        </div>
      </div>
    </template>
  </alchemy-app-content-host>
</template>

<script>
import { mapGetters } from "vuex";
import { AlchemyActivateAccountWidget } from "@encapto/alchemy-login-widget";
import { SET_SESSION } from "../store/mutations";
import { BRANDING, REDIRECT_URL, DEFAULT_LOGIN_BACKGROUND } from "../store/getters";

export default {
  name: "activate-account",
  components: {
    AlchemyActivateAccountWidget
  },
  computed: {
    ...mapGetters({
      branding: BRANDING,
      redirectUrl: REDIRECT_URL,
      defaultLoginBackground: DEFAULT_LOGIN_BACKGROUND
    }),
    backgroundStyle() {
      return `background-image: url(${
        this.branding.loginBackgroundUrl || this.defaultLoginBackground
      }); background-repeat: no-repeat; background-size: cover`;
    },
    activateToken() {
      const { activateToken } = this.$route.params;
      return activateToken;
    },
    activateEmail() {
      const { email } = this.$route.query;
      return email;
    }
  },
  methods: {
    backToLogin() {
      this.$router.push({ name: "login" });
    },
    gotoDashboard(event) {
      const session = event.data;
      console.log("Session!", session);
      // save session to store
      this.$store.commit(SET_SESSION, session);
      this.$router.push(this.redirectUrl);
    }
  }
};
</script>

<style lang="scss" scoped>
#activateAccountHost {
  justify-content: flex-start;
  .container {
    max-width: 540px;
  }
}
@media screen and (min-width: 540px) {
  #activateAccountHost {
    justify-content: center;
  }
}
</style>
