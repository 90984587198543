<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <nav
    data-name="alchemy-app-navbar"
    :class="navBarClasses"
    @mouseenter="() => (subMenuActive = true)"
    @mouseleave="() => (subMenuActive = false)"
  >
    <span data-name="menu-expander" class="alchemy-menu-expander non-mobile-controls"></span>
    <div class="d-flex flex-fill">
      <div class="menu-system" :class="{ 'main-menu-collapse': childItems.length }">
        <div class="main-menu-host">
          <ul data-name="alchemy-app-navbar-main-menu" class="main-menu py-1 py-md-2">
            <li
              v-for="nav in navItems"
              :data-name="'navbar-item-' + nav.label.replace(/ /g, '-')"
              class="menu-list-item"
              v-bind:key="nav.name"
            >
              <router-link
                data-name="item-with-route"
                :to="{ name: nav.name, params: routerParams }"
                :class="{ 'menu-list-item-active': nav.active }"
                class="d-flex align-items-center"
              >
                <div
                  v-if="nav.icon"
                  style="min-width: 1.1rem"
                  class="text-center"
                  v-a-tooltip.bottomright
                  :title="isCompact || subMenu ? nav.label : ''"
                >
                  <sd-wan-icon v-if="nav.icon === 'sd-wan-fixme'" />
                  <alchemy-icon v-else :dataName="nav.name + '-icon'" :icon="nav.icon" />
                </div>
                <div v-if="!isCompact && !subMenu" class="ml-1">
                  {{ nav.label }}
                </div>
              </router-link>
            </li>
          </ul>
          <alchemy-button
            data-name="navbar-compact-toggle"
            variant="link"
            class="btn-block align-self-end btn-menu-collapse non-mobile-controls"
            @click="
              () => {
                isCompact = !isCompact;
                subMenuActive = false;
              }
            "
          >
            <span v-if="!isCompact" data-name="collapse-content">
              <span v-if="!subMenu" class="mr-2">Collapse</span>
              <alchemy-icon icon="angles-left" />
            </span>
            <alchemy-icon v-else data-name="expand-content" icon="angles-right" />
          </alchemy-button>
        </div>
        <div
          v-if="subMenu && (!isCompact || subMenuActive)"
          data-name="alchemy-app-navbar-sub-menu-host"
          class="flex-fill sub-menu-host"
        >
          <div class="menu-breadcrumb">
            <span data-name="alchemy-app-navbar-sub-menu-label" class="menu-breadcrumb-label">
              {{ activeItem.label }}
            </span>
          </div>
          <div class="sub-menu-system">
            <div class="scroll-text-reset">
              <ul data-name="alchemy-app-navbar-sub-menu" class="menu-list">
                <li
                  v-for="nav in childItems"
                  :data-name="`navbar-item-${nav.label}`"
                  class="menu-list-item"
                  v-bind:key="nav.name"
                >
                  <router-link
                    data-name="item-with-route"
                    :to="{ name: nav.name }"
                    class="d-flex align-items-center"
                    :class="{
                      'menu-list-item-active': nav.active,
                      'router-link-active': nav.active
                    }"
                  >
                    {{ nav.label }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import _ from "lodash";
import { iconApi } from "@encapto/alchemy-gui";
import {
  faChartLine,
  faWifi,
  faShieldHalved,
  faCircleInfo,
  faPaintBrush,
  faNetworkWired,
  faGlasses,
  faUser,
  faChartPie,
  faBuildingShield,
  faReceipt,
  faVideo,
  faDollarSign,
  faGlobe,
  faFileSignature,
  faAddressCard,
  faListCheck,
  faBoxOpen,
  faBezierCurve,
  faFileInvoice,
  faFileInvoiceDollar,
  faHeadset,
  faIdCard,
  faGear,
  faTruckFast,
  faSitemap
} from "@fortawesome/free-solid-svg-icons";
import { mapGetters } from "vuex";
import sdWanIcon from "../views/sd-wan-icon.vue";
import { ROUTER_PARAMS } from "../store/getters";

iconApi.library.add(
  faChartLine,
  faWifi,
  faShieldHalved,
  faCircleInfo,
  faPaintBrush,
  faNetworkWired,
  faGlasses,
  faUser,
  faChartPie,
  faReceipt,
  faVideo,
  faDollarSign,
  faGlobe,
  faFileSignature,
  faAddressCard,
  faListCheck,
  faBoxOpen,
  faBezierCurve,
  faFileInvoice,
  faFileInvoiceDollar,
  faHeadset,
  faIdCard,
  faBuildingShield,
  faGear,
  faTruckFast,
  faSitemap
);

export default {
  components: { sdWanIcon },
  name: "alchemy-nav",
  props: {
    navItems: {
      type: Array,
      default: () => []
    },
    mobileMenuVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { isCompact: this.showCompact(), subMenuActive: false };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...mapGetters({
      routerParams: ROUTER_PARAMS
    }),
    activeItem() {
      return this.navItems.find(n => n.active);
    },
    childItems() {
      return _.get(this, "activeItem.children", []);
    },
    subMenu() {
      return !!this.childItems.length;
    },
    navBarSkinClass() {
      return this.$skin && this.$skin.getSkinClass("alchemy-navbar");
    },
    navBarClasses() {
      return {
        "alchemy-navbar": true,
        [this.navBarSkinClass]: true,
        "mobile-menu-collapsed": !this.mobileMenuVisible,
        "main-menu-compact": this.isCompact,
        "sub-menu-host-active": this.isCompact && this.subMenuActive && !!this.subMenu
      };
    }
  },
  methods: {
    showCompact() {
      return window.innerWidth < 1200;
    },
    onResize() {
      this.isCompact = this.showCompact();
    }
  }
};
</script>
