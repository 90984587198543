import childRouter from "./views/childRouter.vue";

/* shared pages */
import Login from "./views/Login.vue";
import Oath from "./views/Oath.vue";
import ResetPassword from "./views/ResetPassword.vue";
import ActivateAccount from "./views/ActivateAccount.vue";

const ErrorComponent = () => import("./views/Error.vue");
const OrderInProgress = () => import("./views/OrderInProgress.vue");
const NoAccess = () => import("./views/NoAccess.vue");

const Profile = () => import("./views/Profile.vue");
const Orders = () => import("./views/Orders.vue");
const Role = () => import("./views/Role.vue");
const AccessGroup = () => import("./views/AccessGroup.vue");
const CatalogueSettings = () => import("./views/CatalogueSettings.vue");

/* cluster admin pages */
const ClusterAdmin = () => import("./views/cluster-admin/ClusterAdmin.vue");
const ClusterManagement = () => import("./views/cluster-admin/ClusterManagement.vue");
const ClusterServiceProviders = () => import("./views/cluster-admin/ClusterServiceProviders.vue");
const ClusterDistributors = () => import("./views/cluster-admin/ClusterDistributors.vue");
const ClusterNetworkSupport = () => import("./views/cluster-admin/ClusterNetworkSupport.vue");
const ClusterEntityVisualization = () =>
  import("./views/cluster-admin/ClusterEntityVisualization.vue");

/* Distributor pages */
const DistributorAdmin = () => import("./views/distributor/DistributorAdmin.vue");

/* SP pages */
const SPDashboard = () => import("./views/service-provider/SPDashboard.vue");
const ManageWifi = () => import("./views/service-provider/ManageWifi.vue");
const Customers = () => import("./views/service-provider/Customers.vue");
const CustomerDetail = () => import("./views/service-provider/CustomerDetail.vue");
const CustomerRole = () => import("./views/service-provider/CustomerRole.vue");
const CustomerAccessGroup = () => import("./views/service-provider/CustomerAccessGroup.vue");
const SPAdmin = () => import("./views/service-provider/SPAdmin.vue");
const Bundles = () => import("./views/service-provider/Bundles.vue");
const Bundle = () => import("./views/service-provider/Bundle.vue");
const Inventory = () => import("./views/service-provider/Inventory.vue");
const InventoryDetail = () => import("./views/service-provider/InventoryDetail.vue");
const SlotList = () => import("./views/service-provider/SlotList.vue");
const Slot = () => import("./views/service-provider/Slot.vue");
const BlueprintProvisioning = () => import("./views/service-provider/BlueprintProvisioning.vue");
const BlueprintDeprovisioning = () =>
  import("./views/service-provider/BlueprintDeprovisioning.vue");
const BlueprintConfiguration = () => import("./views/service-provider/BlueprintConfiguration.vue");
const QuoteList = () => import("./views/service-provider/QuoteList.vue");
const Quote = () => import("./views/service-provider/Quote.vue");
const Bom = () => import("./views/service-provider/Bom.vue");
const Fulfilment = () => import("./views/service-provider/Fulfilment.vue");

/* SMB pages */
const Dashboard = () => import("./views/merchant/Dashboard.vue");
const GlobalDashboard = () => import("./views/merchant/all-sites/GlobalDashboard.vue");
const CompanyManage = () => import("./views/merchant/all-sites/CompanyManage.vue");
const Admin = () => import("./views/merchant/all-sites/Admin.vue");
const Wifi = () => import("./views/merchant/Wifi.vue");
const SDWAN = () => import("./views/merchant/SDWAN.vue");
const Firewall = () => import("./views/merchant/Firewall.vue");
const Camera = () => import("./views/merchant/Camera.vue");
const Networking = () => import("./views/merchant/Networking.vue");
const NetworkingDetail = () => import("./views/merchant/NetworkingDetail.vue");
const GlobalSecurity = () => import("./views/merchant/all-sites/GlobalSecurity.vue");
const SiteSecurity = () => import("./views/merchant/SiteSecurity.vue");

export default [
  {
    path: "/:brandingEntity/:siteName/:siteReference/dashboard",
    name: "dashboard",
    component: Dashboard,
    label: "Dashboard",
    icon: "chart-line"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/global-dashboard",
    name: "global-dashboard",
    component: GlobalDashboard,
    label: "Dashboard",
    icon: "chart-line"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/sp-dashboard",
    name: "sp-dashboard",
    component: SPDashboard,
    label: "Dashboard",
    icon: "chart-line"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/wifi",
    name: "wifi",
    component: Wifi,
    label: "WiFi",
    icon: "wifi"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/security",
    name: "security",
    component: SiteSecurity,
    label: "Security",
    icon: "shield-halved"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/global-security",
    name: "global-security",
    component: GlobalSecurity,
    label: "Security",
    icon: "shield-halved"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/sdwan",
    name: "sdwan",
    component: SDWAN,
    label: "SD-WAN",
    icon: "sd-wan-fixme"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/firewall",
    name: "firewall",
    component: Firewall,
    label: "Firewall",
    icon: "building-shield"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/networking",
    component: childRouter,
    label: "Networking",
    icon: "network-wired",
    children: [
      { path: "", name: "networking", component: Networking },
      { path: ":deviceUuid", name: "networking-detail", component: NetworkingDetail }
    ]
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/camera",
    name: "camera",
    component: Camera,
    label: "Camera",
    icon: "video"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/manage-wifi",
    name: "manage-wifi",
    component: ManageWifi,
    label: "Manage WiFi",
    icon: "wifi"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/cluster-management",
    name: "cluster-management",
    component: ClusterManagement,
    label: "Manage",
    icon: "user"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/cluster-network-support",
    name: "cluster-network-support",
    component: ClusterNetworkSupport,
    label: "Technical Support",
    icon: "network-wired"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/cluster-service-providers",
    name: "cluster-service-providers",
    component: ClusterServiceProviders,
    label: "Service Providers",
    icon: "globe"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/cluster-distributors",
    name: "cluster-distributors",
    component: ClusterDistributors,
    label: "Distributors",
    icon: "truck-fast"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/entity-visualization",
    name: "entity-visualization",
    component: ClusterEntityVisualization,
    label: "Entity Visualization",
    icon: "glasses"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/quotes",
    component: childRouter,
    label: "Quotes",
    icon: "file-invoice-dollar",
    children: [
      { path: "", name: "quotes", component: QuoteList },
      { path: ":quoteUuid/bom", name: "bom", component: Bom },
      { path: ":quoteUuid", name: "quote", component: Quote },
      { path: ":quoteUuid/fulfilment", name: "fulfilment", component: Fulfilment }
    ]
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/orders",
    name: "orders",
    component: Orders,
    label: "Orders",
    icon: "receipt"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/customers",
    component: childRouter,
    label: "Customers",
    icon: "user",
    children: [
      { path: "", name: "customers", component: Customers },
      {
        path: ":companyUuid",
        name: "customer-detail",
        component: CustomerDetail,
        grandChildren: ["customerRole"]
      },
      { path: ":companyUuid/role/:roleUuid", name: "customerRole", component: CustomerRole },
      {
        path: ":companyUuid/access/:accessGroupUuid",
        name: "customerAccess",
        component: CustomerAccessGroup
      }
    ]
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/catalogue",
    redirect: { name: "inventory" },
    name: "catalogue",
    component: childRouter,
    label: "Catalogue",
    icon: "file-invoice",
    children: [
      {
        path: "inventory",
        name: "inventory",
        component: Inventory,
        label: "Inventory",
        grandChildren: ["inventory-detail"]
      },
      {
        path: "slots",
        name: "slots",
        component: SlotList,
        label: "Slots",
        grandChildren: ["slot"]
      },
      {
        path: "bundles",
        name: "bundles",
        component: Bundles,
        label: "Bundles",
        grandChildren: ["bundle"]
      },
      {
        path: "catalogue-settings",
        name: "catalogue-settings",
        component: CatalogueSettings,
        label: "Settings"
      },
      { path: "inventory/:inventoryUuid", name: "inventory-detail", component: InventoryDetail },
      { path: "bundles/:bundleUuid", name: "bundle", component: Bundle },
      { path: "slots/:slotUuid", name: "slot", component: Slot }
    ]
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/blueprint",
    name: "blueprint",
    component: childRouter,
    label: "Blueprint",
    icon: "id-card",
    redirect: { name: "blueprint-provisioning" },
    children: [
      {
        path: "provisioning",
        name: "blueprint-provisioning",
        component: BlueprintProvisioning,
        label: "Provisioning"
      },
      {
        path: "deprovisioning",
        name: "blueprint-deprovisioning",
        component: BlueprintDeprovisioning,
        label: "Deprovisioning"
      },
      {
        path: "configuration",
        name: "blueprint-configuration",
        component: BlueprintConfiguration,
        label: "Configuration"
      }
    ]
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/company-manage",
    name: "company-manage",
    component: CompanyManage,
    label: "Manage",
    icon: "sitemap"
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/cluster-admin",
    component: childRouter,
    label: "Admin",
    icon: "gear",
    children: [
      { path: "", name: "cluster-admin", component: ClusterAdmin },
      { path: "role/:roleUuid", name: "adminRole", component: Role },
      { path: "access/:accessGroupUuid", name: "adminAccess", component: AccessGroup }
    ]
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/distributor-admin",
    component: childRouter,
    label: "Admin",
    icon: "gear",
    children: [
      { path: "", name: "distributor-admin", component: DistributorAdmin },
      { path: "role/:roleUuid", name: "adminRole", component: Role },
      { path: "access/:accessGroupUuid", name: "adminAccess", component: AccessGroup }
    ]
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/sp-admin",
    component: childRouter,
    label: "Admin",
    icon: "gear",
    children: [
      { path: "", name: "sp-admin", component: SPAdmin },
      { path: "role/:roleUuid", name: "adminRole", component: Role },
      { path: "access/:accessGroupUuid", name: "adminAccess", component: AccessGroup }
    ]
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/admin",
    label: "Admin",
    icon: "gear",
    component: childRouter,
    children: [
      { path: "", name: "admin", component: Admin },
      { path: "role/:roleUuid", name: "adminRole", component: Role },
      { path: "access/:accessGroupUuid", name: "adminAccess", component: AccessGroup }
    ]
  },
  { path: "/oath", name: "oath", component: Oath },
  { path: "/:brandingEntity?/oath/initiate", name: "oath-initiate", component: Oath },
  {
    path: "/:brandingEntity?/login",
    name: "login",
    component: Login,
    props: { loginPath: "email-authentication-login" }
  },
  {
    path: "/:brandingEntity/forgotten-password",
    name: "forgotten-password",
    component: Login,
    props: { loginPath: "email-authentication-login", forgottenPassword: true }
  },
  { path: "/:brandingEntity/consent", name: "consent" },
  {
    path: "/:brandingEntity/order-in-progress",
    name: "order-in-progress",
    component: OrderInProgress
  },
  { path: "/:brandingEntity/cluster-admin-login", redirect: { name: "login" } },
  {
    path: "/:brandingEntity/reset-password/:passwordResetToken",
    name: "reset-password",
    component: ResetPassword
  },
  {
    path: "/:brandingEntity/activate-account/:activateToken",
    name: "activate-account",
    component: ActivateAccount
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/profile",
    name: "profile",
    component: Profile
  },
  {
    path: "/:brandingEntity/:siteName/:siteReference/no-access",
    name: "no-access",
    component: NoAccess
  },
  { path: "/error", name: "error", component: ErrorComponent },
  { path: "/:brandingEntity", redirect: { name: "login" } },
  { path: "/", redirect: { name: "login" } },
  { path: "/:pathMatch(.*)*", redirect: "/error" },
  { path: "/:brandingEntity/signOut", name: "signOut" }
];
