<template>
  <alchemy-app-content-host>
    <div>Authorising...</div>
  </alchemy-app-content-host>
</template>

<script>
export default {
  name: "Oath"
};
</script>
